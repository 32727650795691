/**
 * 活动分类相关API
 */

import request from '@/utils/request'

/**
 * 添加活动分类
 * @param params
 */
export function activeTypeAdd(params) {
  return request({
    url: '/admin/goods/categories',
    method: 'post',
    loading: false,
    params,
    log: {
      type: '0',
      name: '活动分类添加'
    }
  })
}

/**
 * 查询活动分类
 * @param id,params
 */
export function activeTypeGet(id, params) {
  return request({
    url: `/admin/goods/categories/${id}/children`,
    method: 'get',
    params
  })
}

/**
 * 删除活动分类
 * @param id
 */
export function activeTypeDel(id, type) {
  return request({
    url: `/admin/goods/categories/updatedel/${id}`,
    method: 'put',
    params: {
      type,
    },
    log: {
      type: '0',
      name: '活动分类删除'
    }
  })
}

/**
 * 修改活动分类
 * @param params
 */
export function activeTypeEdit(params, id) {
  return request({
    url: `/admin/goods/categories/${id}`,
    method: 'put',
    params,
    log: {
      type: '0',
      name: '活动分类修改'
    }
  })
}
