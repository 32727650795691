<template>
  <div>
    <en-table-layout
      :tableData="tableData.data"
      :loading="loading"
      :tips="true"
      @selection-change="selectionChange"
    >
      <!-- 搜索条件 -->
      <div slot="tips" class="TitleBox">
        <div class="conditions">
          <div>搜索种类：</div>
          <el-select class="choose-machine" v-model="params.keyword" placeholder="请选择状态" clearable size="mini">
            <el-option v-for="item in searchList" :key="item" :label="item" :value="item"></el-option>
          </el-select>
        </div>
        <div class="conditions" v-if="params.keyword!==''">
          <div>输入搜素：</div>
          <el-input
            v-model="params.keyvalue"
            :placeholder="params.keyword==='活动名称'?'请输入活动名称':params.keyword==='活动编号'?'请输入活动编号':'请选择搜索种类'"
            class="ipt-default"
            style="width:210px;"
            size="mini"
          ></el-input>
        </div>
        <div class="conditions">
          <div>活动分类：</div>
          <el-cascader
            style="width:69%;"
            expand-trigger="hover"
            :options="typeList"
            @change="activityTypeChange"
            :clearable="true"
            size="mini"
          ></el-cascader>
        </div>
        <div class="conditions" style="margin-left:20px;">
          <div>活动开始日期：</div>
          <el-date-picker
            style="width:260px;margin-right:20px;"
            v-model="times"
            type="daterange"
            align="center"
            :editable="false"
            unlink-panels
            size="mini"
            range-separator="-"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="timestamp"
            @change="timesChange"
          ></el-date-picker>
        </div>
        <div class="conditions" style="margin-right:20px;">
          <el-button @click="POST_activeGoodsActivityPage" type="primary" class="btn-default" size="mini">筛选</el-button>
        </div>
        <div class="conditions">
          <el-button @click="openDialog('examineAll')" type="primary" class="btn-default" size="mini">批量审核</el-button>
        </div>
      </div>
      <template slot="table-columns">
        <!--  选择框 -->
        <el-table-column type="selection" width="55" fixed="left"></el-table-column>
        <!--  活动名称 -->
        <el-table-column label="活动名称" width="225" fixed="left">
          <template slot-scope="scope">
            <div class="imgBox">
              <img :src="scope.row.thumbnail" />
              <div :title="scope.row.goods_name">{{ scope.row.goods_name }}</div>
            </div>
          </template>
        </el-table-column>
        <!-- 所属商户名称 -->
        <el-table-column prop="seller_name" label="所属商户名称" />
        <!--  活动编号 -->
        <el-table-column prop="sn" label="活动编号" />
        <!--  活动一级分类 -->
        <el-table-column prop="category_first" label="活动一级分类" />
        <!--  活动二级分类 -->
        <el-table-column prop="category_second" label="活动二级分类" />
        <!--原价-->
        <el-table-column label="原价">
          <template slot-scope="scope">
            <div style="color:#fe5558;">{{ scope.row.mkt_low?scope.row.mkt_low:0 }}元{{ scope.row.mkt_low === scope.row.mkt_high ? '' : '起' }}</div>
          </template>
        </el-table-column>
        <!--销售价-->
        <el-table-column label="销售价">
          <template slot-scope="scope">
            <div>{{scope.row.price_low}}{{scope.row.price_low===scope.row.price_top?'':'起'}}</div>
          </template>
        </el-table-column>
        <!--  名额限制 -->
        <el-table-column prop="sale_limit_num" label="名额限制" />
        <!--  剩余名额 -->
        <el-table-column prop="quantity" label="剩余名额" />
        <!--  申请上架时间 -->
        <el-table-column prop="apply_time" :formatter="MixinUnixToDate" label="申请上架时间" />
        <!--  活动开始时间 -->
        <el-table-column prop="min_time" :formatter="MixinUnixToDate" label="活动开始时间" />
        <!--  活动结束时间 -->
        <el-table-column prop="max_time" :formatter="MixinUnixToDate" label="活动结束时间" />
        <!--  活动链接地址 -->
        <el-table-column prop="act_url" label="活动链接地址" />
        <!--  操作 -->
        <el-table-column label="操作" width="130" fixed="right">
          <template slot-scope="scope">
            <div class="buttonBox">
              <el-button
                @click="openDialog('examine',scope.row)"
                type="text"
                size="mini"
                style="margin-bottom:5px;"
              >审核</el-button>
              <el-button
                @click="openDialog('details',scope.row)"
                type="text"
                size="mini"
                style="margin-bottom:5px;margin-left:0;"
              >查看</el-button>
            </div>
          </template>
        </el-table-column>
      </template>
      <el-pagination
        v-if="tableData.data"
        slot="pagination"
        @size-change="handlePageSizeChange"
        @current-change="handlePageCurrentChange"
        :current-page="tableData.page_no"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="tableData.page_size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableData.data_total"
      ></el-pagination>
    </en-table-layout>
    <el-dialog :title="dialogTitle" :visible.sync="centerDialogVisible" width="30%" center>
      <div class="dialogContent">
        <div class="left">
          <div>审核：</div>
          <div>原因：</div>
        </div>
        <div class="right">
          <div class="radioBox">
            <el-radio v-model="examineParams.pass" label="1">通过</el-radio>
            <el-radio v-model="examineParams.pass" label="0">拒绝</el-radio>
          </div>
          <el-input
            class="textarea"
            type="textarea"
            :rows="3"
            placeholder="若拒绝,请填写拒绝原因"
            v-model="examineParams.message"
            resize="none"
          ></el-input>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="ok">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import * as API_activityType from "@/api/activityType";
import * as API_activityList from "@/api/activityList";

export default {
  name: "activityList",
  data() {
    return {
      //  列表loading状态
      loading: false,
      //  列表参数
      params: {
        page_no: 1,
        page_size: 10,
        keyword: "",
        category_first: null,
        category_second: null,
        active_start: "",
        active_end: "",
      },
      //  列表数据
      tableData: [],
      // 弹框显示内容
      dialogTitle: "",
      centerDialogVisible: false,

      // 搜索种类
      searchList: ["活动名称", "活动编号", "所属商户名称"],

      // 活动分类
      typeList: [],

      // 活动开始时间
      times: [],

      // 审核意见
      examineParams: {
        pass: "",
        message: "",
        goods_ids: [],
      },
    };
  },
  mounted() {
    // 获取活动分类
    this.GET_activeTypeGet();

    // 获取待审核列表
    this.POST_activeGoodsActivityPage();
  },
  activated() {
    // 获取活动分类
    this.GET_activeTypeGet();

    // 获取待审核列表
    this.POST_activeGoodsActivityPage();
  },
  methods: {
    /** 分页大小发生改变 */
    handlePageSizeChange(size) {
      this.params.page_size = size;
      this.POST_activeGoodsActivityPage();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange(page) {
      this.params.page_no = page;
      this.POST_activeGoodsActivityPage();
    },

    //  查询活动分类列表
    GET_activeTypeGet() {
      API_activityType.activeTypeGet(0, {}).then((res) => {
        this.typeList = [];
        res.forEach((item1, index1) => {
          var jsonParent = {};
          jsonParent.value = item1.category_id;
          jsonParent.label = item1.name;
          var children = [];
          if (item1.children && item1.children.length > 0) {
            item1.children.forEach((item2, index2) => {
              var jsonChild = {};
              jsonChild.value = item2.category_id;
              jsonChild.label = item2.name;
              children.push(jsonChild);
            });
            jsonParent.children = children;
          }
          this.typeList.push(jsonParent);
        });
      });
    },

    /**  选择活动分类 */
    activityTypeChange(val) {
      this.params.category_first = null;
      this.params.category_second = null;
      this.typeList.forEach((item1) => {
        if (item1.value === val[0]) {
          this.params.category_first = item1.value;
          if (item1.children && item1.children.length > 0) {
            item1.children.forEach((item2) => {
              if (item2.value === val[1]) {
                this.params.category_second = item2.value;
              }
            });
          }
        }
      });
    },

    /**  选择查询时间 */
    timesChange() {
      console.log(this.times);
      if (this.times) {
        this.params.active_start = this.times[0] / 1000;
        this.params.active_end = this.times[1] / 1000;
      } else {
        this.params.active_start = "";
        this.params.active_end = "";
      }
    },

    /** 获取待审核活动列表 */
    POST_activeGoodsActivityPage() {
      API_activityList.activeGoodsActivityPage(this.params).then((res) => {
        this.tableData = res;
        console.log(res);
      });
    },

    /** 审核活动 */
    POST_activeGoodsActivityBatchAudit() {
      if (this.examineParams.pass === "") {
        this.$message.error("请选择审核意见！");
        return false;
      }
      if (
        this.examineParams.message === "" &&
        this.examineParams.pass === "0"
      ) {
        this.$message.error("请填写拒绝原因！");
        return false;
      }
      const isSuccess = this.examineParams.pass === "0" ? '失败' : '成功';
      const reason = this.examineParams.pass === "0" ? this.examineParams.message : undefined;
      const sn = this.examineParams.pass === "0" ? this.examineParams._params.sn : undefined;
      const seller_name = this.examineParams.pass === "0" ? this.examineParams._params.seller_name : undefined;
      const goods_name = this.examineParams.pass === "0" ? this.examineParams._params.goods_name : undefined;
      delete this.examineParams._params
      API_activityList.activeGoodsActivityBatchAudit(this.examineParams, isSuccess, reason, sn, seller_name, goods_name).then(
        (res) => {
          this.examineParams.pass = "";
          this.examineParams.message = "";
          this.examineParams.goods_ids = [];
          this.centerDialogVisible = false;
          this.POST_activeGoodsActivityPage();
        }
      );
    },

    /** 打开弹窗或页面跳转 */
    openDialog(type, row) {
      this.examineParams.pass = "";
      this.examineParams.message = "";
      this.dialogTitle = "审核操作";
      if (type === "examine") {
        this.examineParams.goods_ids = [];
        this.examineParams.goods_ids.push(row.goods_id);
        const _params = {}
        _params['sn'] = row.sn
        _params['goods_name'] = row.goods_name
        _params['seller_name'] = row.seller_name
        console.log(_params)
        this.examineParams['_params'] = _params
      } else if (type === "details") {
        this.$router.push({
          name: '活动详情',
          params: { id: row.goods_id },
        });
        return false;
      } else if (type === "examineAll") {
        if (this.examineParams.goods_ids.length === 0) {
          this.$message.error("请选择需要审核的数据！");
          return false;
        }
      }
      this.centerDialogVisible = true;
    },

    /** 弹窗点击确定 */
    ok() {
      this.POST_activeGoodsActivityBatchAudit();
    },

    /** 批量审核 */
    examineAll() {
      console.log(this.chooseList);
    },

    // 列表选择状态改变
    selectionChange(val) {
      this.examineParams.goods_ids = [];
      val.forEach((item) => {
        this.examineParams.goods_ids.push(item.goods_id);
      });
    },
  },
};
</script>

<style type="text/scss" lang="scss" scoped>
::v-deep .el-table td:not(.is-left) {
  text-align: center;
}

::v-deep .toolbar {
  padding: 0 !important;
}

.TitleBox {
  display: flex;
  flex-wrap: wrap;

  .conditions {
    display: flex;
    align-items: center;

    div {
      font-size: 15px;
      font-weight: 500;
      color: rgba(68, 68, 68, 1);
    }

    margin-bottom: 20px;

    .choose-machine {
      width: 60%;
    }
  }
}

.imgBox {
  display: flex;
  align-items: center;

  img {
    width: 64px;
    height: 48px;
    margin-right: 10px;
  }

  div {
    width: 150px;
    font-size: 16px;
    line-height: 24px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
}

.buttonBox {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dialogContent {
  width: 100%;
  display: flex;

  .left {
    width: 100px;
    text-align: right;

    div {
      font-size: 16px;
      line-height: 30px;
    }
  }

  .right {
    flex: 1;

    .radioBox {
      display: flex;
      align-items: center;
      height: 30px;
    }

    .textarea {
      margin-top: 10px;
    }
  }
}
</style>
