/**
 * 活动分类相关API
 */

import request from '@/utils/request'

/**
 * 查询待审核活动列表
 * @param params
 */
export function activeGoodsActivityPage(params) {
  return request({
    url: '/admin/active/goodsActivity/page',
    method: 'post',
    loading: false,
    params
  })
}

/**
 * 审核活动
 * @param params
 */
export function activeGoodsActivityBatchAudit(params, isSuccess, reason, sn, goods_name, seller_name) {
  return request({
    url: '/admin/active/goodsActivity/batch/audit',
    method: 'post',
    loading: false,
    params,
    log: {
      type: '0',
      name:
        '活动审核' + isSuccess + (sn ? (', 活动编号:' + sn) : '') +
        (goods_name ? (',活动名称:' + goods_name) : '') +
        (seller_name ? (',商户名称:' + seller_name) : '') +
          (reason ? ('，原因:' + reason) : '')
    }
  })
}

/**
 * 查询活动列表
 * @param params
 */
export function activeGoodsActivityPage2(params) {
  return request({
    url: '/admin/active/goodsActivity/page2',
    method: 'post',
    loading: false,
    params
  })
}

/**
 * 商品下架
 * @param goods_id,params
 */
export function activeGoodsActivityUnder(goods_id, params) {
  return request({
    url: `/admin/active/goodsActivity/${goods_id}/under`,
    method: 'put',
    loading: false,
    params
  })
}

/**
 * 查询活动详情
 * @param id
 */
export function activeGoodsActivityDetails(id) {
  return request({
    url: `/admin/active/goodsActivity/${id}`,
    method: 'get',
    loading: false
  })
}
